<template>
  <div class="accountDeletion">
    <div class="step1" v-if="step == 1">
      <van-radio-group v-model="cancellationIssue">
        <div class="label">{{ $t.settings.label1 }}</div>
        <van-cell-group>
          <van-cell
            :title="$t.settings.issue1"
            clickable
            @click="cancellationIssue = '1'"
          >
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell
            :title="$t.settings.issue2"
            clickable
            @click="cancellationIssue = '2'"
          >
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
          <van-cell
            :title="$t.settings.issue3"
            clickable
            @click="cancellationIssue = '3'"
          >
            <template #right-icon>
              <van-radio name="3" />
            </template>
          </van-cell>
          <van-cell
            :title="$t.settings.issue4"
            clickable
            @click="cancellationIssue = '4'"
          >
            <template #right-icon>
              <van-radio name="4" />
            </template>
          </van-cell>
        </van-cell-group>
        <div class="label">{{ $t.settings.label2 }}</div>
        <van-cell-group>
          <van-cell
            :title="$t.settings.issue5"
            clickable
            @click="cancellationIssue = '5'"
          >
            <template #right-icon>
              <van-radio name="5" />
            </template>
          </van-cell>
          <van-cell
            :title="$t.settings.issue6"
            clickable
            @click="cancellationIssue = '6'"
          >
            <template #right-icon>
              <van-radio name="6" />
            </template>
          </van-cell>
        </van-cell-group>
        <div class="label">{{ $t.settings.label3 }}</div>
        <van-cell-group>
          <van-cell
            :title="$t.settings.issue7"
            clickable
            @click="cancellationIssue = '7'"
          >
            <template #right-icon>
              <van-radio name="7" />
            </template>
          </van-cell>
          <van-cell
            :title="$t.settings.issue8"
            clickable
            @click="cancellationIssue = '8'"
          >
            <template #right-icon>
              <van-radio name="8" />
            </template>
          </van-cell>

          <van-field
            :disabled="cancellationIssue != 8 ? true : false"
            v-model="otherProblemReason"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            :placeholder="$t.settings.inputText"
            show-word-limit
          />
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="step2" v-if="step == 2">
      <h1 class="title">账号注销</h1>
      <p class="titleInfo">Account Cancellation</p>
      <p class="s1 green">为保障您的权益，请再次确认:</p>
      <p class="s1 green">
        To protect your rights, please confirm the following:
      </p>
      <p class="s1">
        1、您的所有交易记录将被清空，请确保所有交易已完结且无纠纷。账号注销后因历史交易可能产生的退换货、维权相关的资金退回等权益将视作自动放弃。
      </p>
      <p class="s1">
        1. All your transaction records will be cleared. Please ensure that all
        transactions are complete and free of disputes. After the account is
        canceled, any returns or exchanges of goods and funds related to rights
        protection will be considered automatically waived.
      </p>
      <p class="s1">
        2、您的身份信息、账号信息、积分、优惠券等会员权益将被清空且无法恢复。
      </p>
      <p class="s1">
        2. Your personal information, account information, points, coupons, and
        other membership benefits will be deleted and cannot be restored.
      </p>
      <p class="s1 green">注销前请进行以下验证:</p>
      <p class="s1 green">
        Please complete the following verifications before cancellation:
      </p>
      <p class="s1">1、账号不存在近期交易订单</p>
      <p class="s1">
        1、No recent transaction orders associated with the account.
      </p>
      <p class="s1">
        您的账号没有未完成的订单、没有已完成但未满180天的订单等。
      </p>
      <p class="s1">
        Your account should have no pending orders and no completed orders that
        are less than 180 days old.
      </p>
      <p class="s1">2、账号相关资产权益已结清</p>
      <p class="s1">2、All account-related asset rights are settled.</p>
      <p class="s1">您的账号不存在待支付、待退还、待提现的款项。</p>
      <p class="s1">
        Your account has no outstanding payments, refunds, or withdrawal
        requests.
      </p>
      <p class="s1">
        3、C端账号的注销将自动导致与之关联的B端店铺（如有）同时注销
      </p>
      <p class="s1">
        3、The cancellation of the C-end account will automatically result in
        the simultaneous cancellation of the associated B-end store (if any)
      </p>
      <p class="s1">
        根据相关法律法规及店铺经营的交易安全需求，C端账号的注销将自动导致与之关联的B端店铺（如有）同时注销。注销C端账号时，账户持有人视为自愿同意注销其VANCHEEN
        B端店铺（如有）。
      </p>
      <p class="s1">
        In accordance with relevant laws and regulations, as well as the
        transaction security needs of store operations, the cancellation of the
        C-end account will automatically lead to the cancellation of the
        associated B-end store (if any). When the C-end account is canceled, the
        account holder is deemed to voluntarily agree to the cancellation of
        their VANCHEEN B-end store (if any).
      </p>
      <p class="s1">4、账号没有未完结的服务</p>
      <p class="s1">4、The account has no outstanding services.</p>

      <h1 class="title">账号注销重要提醒</h1>
      <p class="titleInfo">Important Reminders for Account Cancellation</p>

      <p class="s1">
        在您确认注销您的VANCHEEN平台账号之前，请您充分阅读、理解并同意下列事项（特别是以粗体下划线标注的内容）
      </p>
      <p class="s1">
        Before you confirm the cancellation of your VANCHEEN platform account,
        please read, understand, and agree to the following matters (especially
        the content marked in bold and underlined)
      </p>
      <p class="s1">
        （如您不同意下列任一内容，或无法准确理解任何条款的含义，请不要进行账号注销操作。您通过网络页面确认申请注销，视为您同意接受本提醒所有内容）
      </p>
      <p class="s1">
        (If you do not agree with any of the following or cannot accurately
        understand the meaning of any terms, please do not proceed with the
        account cancellation. Confirming the cancellation request through the
        online page is considered your agreement to accept all the content of
        this reminder.)
      </p>
      <p class="s1">
        1.您所申请注销的VANCHEEN平台账号应当是您依照与提供服务的平台之约定注册并由该平台提供给您本人的账号。
      </p>
      <p class="s1">
        1.The VANCHEEN platform account you are applying to cancel must be
        registered in accordance with the agreements with the service-providing
        platform and must be an account that was provided to you personally by
        that platform. You must ensure that you have the right to decide on the
        cancellation of this account and that it does not infringe
      </p>
      <p class="s1">
        2.您应确保您有权决定该账号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何投诉争议，由您自行承担并使VANCHEEN平台免责。
      </p>
      <p class="s1">
        2. Upon any third party's legitimate rights and interests. If any
        complaints or disputes arise as a result, you shall bear all
        responsibility and indemnify the VANCHEEN platform.
      </p>
      <p class="s1">
        3.在您申请注销VANCHEEN平台账号前，您应当确保该账号处于正常状态，且在注销申请提起时该账号下无未完结的交易，无进行中的投诉纠纷或处罚，亦无任何拖欠的款项（比如，VANCHEEN平台账号注销时，须确保该账号下不存在VANCHEEN贷款、退款VANCHEEN代垫款项及其他因交易产生的欠款等），并符合VANCHEEN平台账号注销的其他条件。
      </p>
      <p class="s1">
        3.Before applying for the cancellation of your VANCHEEN platform
        account, you must ensure that the account is in good standing, that
        there are no unfinished transactions at the time of application, no
        ongoing complaints or disputes, and no outstanding payments. For
        example, when canceling a VANCHEEN account, you must ensure there are no
        VANCHEEN loans, refund advances, or other debts arising from
        transactions under the account. You must also meet all other conditions
        for account cancellation.
      </p>
      <p class="s1">
        4.您充分知晓本VANCHEEN平台账号的注销将导致本平台终止为您提供服务，VANCHEEN平台服务协议约定的双方权利义务将终止（依本通知其他条款另行约定不得终止的或依其性质不能终止的除外），同时还可能对于该账号产生包括但不限于如下结果：
      </p>
      <p class="s1">
        4.You fully understand that the cancellation of this VANCHEEN platform
        account will result in the termination of the services provided to you
        by this platform, and the rights and obligations of both parties as
        stipulated in the VANCHEEN platform service agreement will also
        terminate (except for any terms in this notice that expressly state they
        cannot be terminated or are inherently incapable of being terminated).
        Additionally, the cancellation may lead to the following consequences:
      </p>
      <p class="s1">
        使用本账号均将无法再次登录VANCHEEN平台内各网站及客户端进行任何操作；
      </p>
      <p class="s1">
        You will be unable to log into any websites or clients of the VANCHEEN
        platform using this account for any operations.
      </p>
      <p class="s1">您的账号名称将被释放，供其他用户进行注册；</p>
      <p class="s1">
        Your account name will be released for registration by other users.
      </p>
      <p class="s1">您的账号内所有历史记录信息将被清除并无法再次找回；</p>
      <p class="s1">
        All historical record information in your account will be cleared and
        cannot be retrieved.
      </p>
      <p class="s1">
        您的账号内所获得的任何优惠信息都将作废（比如，注销VANCHEEN账号的，该账号内的相关积分、店铺优惠券、商品优惠券、店铺红包等都将作废）。
      </p>
      <p class="s1">
        Any benefits obtained within your account will be rendered void (for
        example, if the VANCHEEN account is canceled, all relevant points, store
        coupons, product coupons, store red packets, etc., will become invalid).
      </p>
      <p class="s1">
        5.您应当按照本平台公示的流程进行账号注销，且您承诺该账号注销申请一经提交，您不会以任何理由要求本平台予以撤销。
      </p>
      <p class="s1">
        5.You must follow the process publicly disclosed by this platform for
        account cancellation, and you commit that once the cancellation
        application for the account is submitted, you will not request the
        platform to revoke it for any reason.
      </p>
      <p class="s1">
        6.特别地，如果您的VANCHEEN平台账号在连续6个月内没有任何操作行为，且满足VANCHEEN平台其他账号注销条件时，本平台有权依照适用的法律和VANCHEEN平台服务协议主动进行注销。
      </p>
      <p class="s1">
        6.Specifically, if your VANCHEEN platform account has had no operational
        activity for six consecutive months and meets other account cancellation
        conditions, this platform reserves the right to proactively cancel the
        account in accordance with applicable laws and the VANCHEEN platform
        service agreement.
      </p>
      <p class="s1">
        7.您申请注销的VANCHEEN平台账号所对应的银行账号（如有）应当不存在任何由于该账号被注销而导致的未了结的合同关系与其他基于该账号的存在而产生或维持的权利义务，以及本公司认为注销该账号会由此产生未了结的权利义务而产生纠纷的情况。
      </p>
      <p class="s1">
        7.The bank account corresponding to the VANCHEEN platform account you
        wish to cancel (if applicable) must not have any unresolved contractual
        relationships or other rights and obligations arising from the existence
        of that account due to its cancellation. Furthermore, there should be no
        disputes arising from the company’s belief that canceling the account
        will result in unresolved rights and obligations.
      </p>
      <p class="s1">
        8.本通知未尽事宜，本平台依照与您约定的VANCHEEN平台服务协议执行。
      </p>
      <p class="s1">
        8.For matters not covered in this notice, this platform will address
        them in accordance with the VANCHEEN platform service agreement you have
        agreed to.
      </p>
    </div>

    <div class="step2" v-if="step == 3">
      <div class="icon">
        <img src="@/assets/img/userManage/color-success.png" />
      </div>
      <div class="text">{{ $t.settings.accountDeleteSuccess }}</div>
    </div>

    <div class="agreeBox" @click="nextStep" v-if="step == 1">
      <div class="agree">{{ $t.settings.nextStep }}</div>
    </div>
    <div class="agreeBox" v-if="step == 2">
      <div class="termsTitle">
        <van-checkbox
          v-model="checked"
          checked-color="#01aaa3"
          icon-size="20px"
        ></van-checkbox>
        <div class="termsText">{{ $t.settings.termsText }}</div>
      </div>
      <div :class="!checked ? 'disabled' : 'agree'" @click="showAlert">
        {{ $t.settings.accountDelete }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { LOG_OUT } from "@/store/mutation-types";
import Vue from "vue";
import { RadioGroup, Radio, Cell, CellGroup, Field, Checkbox } from "vant";
Vue.use(Radio)
  .use(RadioGroup)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Checkbox);

export default {
  name: "accountDeletion",
  data() {
    return {
      cancellationIssue: "",
      otherProblemReason: "",
      step: 1,
      checked: false,
    };
  },
  created() {},
  methods: {
    ...mapMutations([LOG_OUT]),
    nextStep() {
      if (!this.cancellationIssue) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.settings.error,
        });
        return;
      }
      if (this.cancellationIssue == 8 && !this.otherProblemReason) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.settings.inputText,
        });
        return;
      }
      this.step = 2;
    },
    showAlert() {
      if (!this.checked) {
        return;
      }
      this.$commonMethod.customAlert(
        {
          message: this.$t.alert.cancellationText,
          cancelButtonText: this.$t.alert.cancelButtonText,
          confirmButtonText: this.$t.alert.confirmButtonText,
        },
        () => {
          this.accountCancellation();
        },
        () => {}
      );
    },
    accountCancellation() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        CancellationIssue: this.cancellationIssue,
        OtherProblemReason: this.otherProblemReason,
      };
      this.$api.login
        .accountCancellation(param)
        .then(() => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.step = 3;
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.loginOut();
          }, 3000);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    loginOut() {
      this.LOG_OUT();
      this.$commonEnv.commonAction(this.$commonEnv.actionType.userToken, {
        token: "",
      });
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/login",
        name: "Login",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.accountDeletion {
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .green {
    color: #01aaa3;
    font-weight: bold;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
  .step1 {
    .van-radio-group {
      .label {
        margin-top: 2.5%;
        margin-left: 5%;
        font-size: 14px;
        color: #999;
      }
      .van-cell-group {
        margin-top: 2.5%;
        ::v-deep .van-radio__icon--checked {
          .van-icon-success {
            background-color: #01aaa3;
            border-color: #01aaa3;
          }
        }
      }
    }
  }
  .step2 {
    width: 90%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    .icon {
      margin-bottom: 5%;
    }
    .text {
      font-size: 16px;
    }
  }
  .agreeBox {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    padding: 3% 0;
    text-align: center;
    border-top: 1px solid #e5e5e5;
    .termsTitle {
      margin-left: 10%;
      margin-bottom: 3%;
      font-size: 14px;
      color: #ffffff;
      .van-checkbox {
        width: 10%;
        display: inline-block;
        vertical-align: middle;
        ::v-deep .van-checkbox__label {
          color: #ffffff;
        }
      }
      .termsText {
        color: #01aaa3;
        font-weight: bold;
        width: 90%;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }
    }
    .disabled {
      width: 80%;
      margin: auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: #e5e5e5;
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
    .agree {
      width: 80%;
      margin: auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
}
</style>
